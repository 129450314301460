import React, { useState, useEffect , useCallback } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import Container from "react-bootstrap/Container"
import "./styles.css"
import "./AutoComplete.css"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import FloatingLabel from "react-bootstrap/FloatingLabel"
import "react-phone-input-2/lib/style.css"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
// import { Search } from "react-bootstrap-icons";
import Card from "react-bootstrap/Card"
// import { InputGroup } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom"
import "./AutoComplete.css"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { 
  fhirServer,
  r5FhirServerCdsServices,
  r5FhirServerCdsServicesNyre,
 } from "../config.js"
import { interaksjonUrl } from "../config.js"
import { bivirkningUrl } from "../config.js"
import AutoSuggest from "./AutoSuggest"
// import ConditionEditModal from "./EditModal.jsx" // Redigerknapp under utvikling
import { CurrentCdsComponent } from "./CdsLegemiddelgjennomgang"
import { Modal } from 'react-bootstrap'
import "./Cds.css"; // Bruker samme CSS som CdsResponseComponent
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { CurrentCdsComponentNyre } from "./CdsKidneyListe";
import kidneySymbolImage from './assets/nyre.png';
import { createPatientSummaryPrompt } from './KI-prompt';
import { createPatientSummaryPromptMed } from './KI-med-prompt';
//import GptChat from './KI-gpt-chat'; // Import GptChat
//import GptChatMed from './KI-med-gpt-chat'; // Import GptChat
import { streamFromLLM } from './KI-chatboxIndex';
import ChatBox from './KI-chatbox';
//import { streamFromLLMmed } from './KI-chatboxIndexMed';

import GptChatMedStream from './KI-chatboxMed';
import {useAuthToken} from "../context/useAuthToken";



function Patient() {
  const [patientId, setPatientId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [EmergencyfirstName, setEmergencyFirstName] = useState("");
  const [EmergencylastName, setEmergencyLastName] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [line, setline] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [EmergencyphoneNo, setEmergencyphoneNo] = useState("");
  const [ContactTitle, setContactTitle] = useState("");
  const [email, setEmail] = useState("");
  const [searchedPatient, setSearchedPatient] = useState(null);
  const [patientObservation, setPatientObservation] = useState(null);
  const [patientCondition, setPatientCondition] = useState(null);
  const [patientMedications, setPatientMedication] = useState(null);
  const [error, setError] = useState("");
  const [showPatient, setShowPatient] = useState(false);
  const [showMoreId, setShowMoreId] = useState("");
  //const [showGptModal, setShowGptModal] = useState(false);
  const [gptPrompt, setGptPrompt] = useState("");

  const getToken = useAuthToken()

 // const [showGptModalMed, setShowGptModalMed] = useState(false);
  const [message, setMessage] = useState(""); // Håndtere meldingen
  //const [currentMedication, setCurrentMedication] = useState(null); // Håndtere aktuell medisin
  const [gptPromptMed, setGptPromptMed] = useState(""); // Håndtere GPT-prompt
  const [showGptModalMed, setShowGptModalMed] = useState(false); // Modal for KI-GPT


  const [showGptModal, setShowGptModal] = useState(false);

  const handleGeneratePrompt = async () => {
    const prompt = createPatientSummaryPrompt(searchedPatient, patientCondition, patientMedications, patientObservation);
    console.log("Generated Prompt:", prompt);

    setGptPrompt(prompt);
    setShowGptModal(true);
  };

  const handleCloseGptModal = () => {
    setShowGptModal(false);
    setGptPrompt("");
  };


  const handleGeneratePromptMed = (medication) => {
    const prompt = createPatientSummaryPromptMed(searchedPatient, patientCondition, patientMedications, patientObservation, medication, message);
    setGptPromptMed(prompt);
    setShowGptModalMed(true);
  };

  const handleCloseGptModalMed = () => {
    setShowGptModalMed(false);
    setMessage("");
  };




/*
  useEffect(() => {
    if (currentMedication && message && searchedPatient && patientCondition && patientMedications && patientObservation) {
      const prompt = createPatientSummaryPromptMed(searchedPatient, patientCondition, patientMedications, patientObservation, currentMedication, message);
      setGptPromptMed(prompt);
    }
  }, [currentMedication, message, searchedPatient, patientCondition, patientMedications, patientObservation]);
*/

/*

// GPT Med

const handleGeneratePromptMed = async (currentMedication) => {
  const prompt = createPatientSummaryPromptMed(searchedPatient, patientCondition, patientMedications, patientObservation, currentMedication);
  console.log("Generated Prompt:", prompt);

  setGptPromptMed(prompt);
  setShowGptModalMed(true);
};


  const handleCloseGptModalMed = () => {
    setShowGptModalMed(false);
    setGptPromptMed("");
  };

  // GPT Med
*/

  //RISKIOLEGEMIDLER
  // const [cdsCardData, setcdsCardData] = useState(null);
  const [showCdsModal, setShowCdsModal] = useState(false); // Definer state-variabler for modal-visningen og det nåværende CDS-kortet
  const [currentCdsCard, setCurrentCdsCard] = useState(null); // Definer state-variabler for modal-visningen og det nåværende CDS-kortet
  const [patientAge, setPatientAge] = useState(null); // Denne tilstanden vil holde den beregnede alderen
  // Endre onClose funksjonen til å håndtere lukking av modal
  const onClose = () => {
    setShowCdsModal(false); // Dette vil skjule modalen
    setCurrentCdsCard(null); // Dette vil fjerne det nåværende kortet fra visning
  }

  // const [cdsCardDataNyre, setcdsCardDataNyre] = useState(null);
  const [showCdsModalNyre, setShowCdsModalNyre] = useState(null); // Definer state-variabler for modal-visningen og det nåværende CDS-kortet
  const [currentCdsCardNyre, setCurrentCdsCardNyre] = useState(null); // Definer state-variabler for modal-visningen og det nåværende CDS-kortet
      // Endre onCloseNyre funksjonen til å håndtere lukking av modal
  const onCloseNyre = () => {
      setShowCdsModalNyre(null); // Dette vil skjule modalen
      setCurrentCdsCardNyre(null); // Dette vil fjerne det nåværende kortet fra visning
    }



  const resetData = () => {
    setPatientId("")
    setFirstName("")
    setLastName("")
    setEmergencyFirstName("")
    setEmergencyLastName("")
    setDob("")
    setGender("")
    setline("")
    setCity("")
    setPostalCode("")
    setCountry("")
    setPhoneNo("")
    setEmergencyphoneNo("")
    setContactTitle("")
    setEmail("")
    setPatientCondition(null)
    setPatientObservation(null)
    setSearchedPatient(null)
  }

  const getExistingPatient = async (id) => {
    setError("")
    // changed to a more meaningful name
    let existingPatientResponse = undefined

    if (id) {
      existingPatientResponse = await fetch(fhirServer + `/Patient?_id=${id}`)
      // } else if (searchedEmail) {
      //   existingPatientRes = await fetch(
      //     fhirServer + `/Patient?email=${searchedEmail}`
      //   );
    } else return

    // changed to a more meaningful name
    const existingPatientData = await existingPatientResponse.json()
    if (existingPatientData.total === 0) {
      cachedResetData()
      setError("No Result Found")
      return
    }

    const existingPatient = existingPatientData.entry[0].resource

    fetch(fhirServer + `/Patient/${existingPatient.id}/$everything?_count=2000`)
    .then((res) => res.json())
    .then((data) => {
      console.log("Data fra FHIR-server:", data);
  
      // Filtrer basert på resourceType for hver kategori
      const observations = data.entry
        .filter(entry => entry.resource.resourceType === "Observation")
        .map(entry => entry.resource);
      console.log("Observations:", observations);
  
      const conditions = data.entry
        .filter(entry => entry.resource.resourceType === "Condition")
        .map(entry => entry.resource);
      console.log("Conditions:", conditions);
  
      const medications = data.entry
        .filter(entry => entry.resource.resourceType === "MedicationRequest")
        .map(entry => entry.resource);
      console.log("Medications:", medications);
  
      // Sett tilstander basert på filtrerte resultater
      setPatientObservation(observations);
      setPatientCondition(conditions);
      setPatientMedication(medications);
    });



  
    setPatientId(existingPatient?.id)
    setFirstName(existingPatient?.name[0]?.given[0] || "")
    setLastName(existingPatient?.name[0]?.family || "")
    const email = existingPatient.telecom.find(
      (item) => item.system === "email"
    )?.value
    const phone = existingPatient.telecom.find(
      (item) => item.system === "phone"
    )?.value

    setPhoneNo(phone || "")
    setEmail(email || "")

    setline(existingPatient?.address[0]?.line[0] || "")
    setCountry(existingPatient?.address[0]?.country || "")
    setPostalCode(existingPatient?.address[0]?.postalCode || "")
    setCity(existingPatient?.address[0]?.city || "")

    setEmergencyFirstName(existingPatient.contact[0]?.name?.given[0] || "")
    setEmergencyLastName(existingPatient.contact[0]?.name?.family || "")
    setEmergencyphoneNo(existingPatient.contact[0]?.telecom[0].value || "")
    // here was a bug zone:
    // existingPatient.contact[0] not always has "relationship" field
    setContactTitle(getContactTitle(existingPatient))
    setGender(existingPatient.gender || "")
    setDob(existingPatient.birthDate || "")

    //...that's why this setter did not work
    // and condition was not met while render
    setSearchedPatient(existingPatient)
  }

  //...to fix this this func was created:
  const getContactTitle = (patient) => {
    if (
      patient.contact?.length > 0 &&
      patient.contact[0].relationship?.length > 0 &&
      patient.contact[0].relationship[0].coding?.length > 0
    )
      return patient.contact[0].relationship[0].coding[0].display ?? ""
    return ""
  }

  const cachedResetData = useCallback(resetData, [])
  const cachedGetExistingPatient = useCallback(getExistingPatient, [
    cachedResetData,
  ])

  const params = useParams()
  // Data for Posts
  useEffect(() => {
    if (params.patientId) {
      cachedGetExistingPatient(params.patientId)
    }
  }, [params, cachedGetExistingPatient])

  const navigator = useNavigate()

  const getSuggestedPatient = async (suggestion) => {
    console.log("suggestion", suggestion)
    navigator(`/patient/${suggestion?.resource.id}`)
    await getExistingPatient(suggestion?.resource.id)
  }



  const deleteFhirResource = async (resourceType, resourceId) => {
    const isConfirmed = window.confirm(`Er du sikker på at du vil slette denne ${resourceType.toLowerCase()}?`);
  
    if (isConfirmed) {
      const url = `${fhirServer}/${resourceType}/${resourceId}`;
      try {
        const response = await fetch(url, {
          method: 'DELETE',
          // Husk å legge til nødvendige headers her
        });
        if (response.ok) {
          if (resourceType === 'Condition') {
            setPatientCondition(currentConditions => currentConditions.filter(condition => condition.id !== resourceId));
          } else if (resourceType === 'Observation') {
            setPatientObservation(currentObservations => currentObservations.filter(observation => observation.id !== resourceId));
          } else if (resourceType === 'MedicationRequest') {
            setPatientMedication(currentMedicationRequests => currentMedicationRequests.filter(medicationRequest => medicationRequest.id !== resourceId));
          }
          console.log(`${resourceType} ble slettet`);

        } else {
          console.error(`Feil ved sletting av ${resourceType}`);
        }
      } catch (error) {
        console.error(`Nettverksfeil ved sletting av ${resourceType}`, error);
      }
    }
  }
  


  //RISKIOLEGEMIDLER
  //RISKIOLEGEMIDLER
  //RISKIOLEGEMIDLER


  // Denne funksjonen beregner alderen basert på en fødselsdato
  const calculateAge = (dob) => {
    if (!dob) return 0; // Hvis ingen fødselsdato er gitt, returner 0

    const birthDate = new Date(dob);
    const ageDifMs = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  // useEffect vil kjøre når `dob` endres og vil oppdatere `patientAge`
  useEffect(() => {
    const age = calculateAge(dob);
    setPatientAge(age);
    console.log(`Alder: ${age}`); // Dette vil skrive ut alderen til konsollen
  }, [dob]);


  // Definerer en liste over medikamentkategorier som kun skal vises for pasienter over 65
  const ageSensitiveSummaries = [
    "<div xmlns=\"http://www.w3.org/1999/xhtml\">TCA (trisykliske antidepressiver)</div>",
    "<div xmlns=\"http://www.w3.org/1999/xhtml\">Antipsykotika (nevroleptika)</div>",
    "<div xmlns=\"http://www.w3.org/1999/xhtml\">Antihistaminer – 1. generasjon</div>",
    "<div xmlns=\"http://www.w3.org/1999/xhtml\">Antikolinerge midler mot overaktiv blære</div>",
    "<div xmlns=\"http://www.w3.org/1999/xhtml\">Sulfonylurea</div>"
  ];



  // Funksjon som åpner modalen med det valgte CDS-kortet
  const handleShowCdsCard = (cdsCardData) => {
    setCurrentCdsCard(cdsCardData);
    setShowCdsModal(true);
  };


  const handleRiskMedicationsClick = async () => {
    try {
      const response = await sendCDsHooksRequest();
      if (response && Array.isArray(response.cards)) {
        // Log the full response
        console.log("CDS Hooks Response:", response);
  
        // Iterate over patientMedications to associate them with the correct CDS card
        const updatedMedications = patientMedications.map(medication => {
          // Find the first action within all cards where this medication has a warning
          const medId = medication.id;
          const actionWithWarning = response.cards.flatMap(card => card.suggestions)
            .flatMap(suggestion => suggestion.actions)
            .find(action => action.resource.id === medId && 
                            action.resource.extension.some(ext => 
                              ext.url === "https://felleskatalogen.no/legemiddelgjennomgang/harAdvarsel" && 
                              ext.valueBoolean === true));
                              console.log("medication.id", medication.id);


         // If there is an action with a warning, associate the corresponding card with the medication
         if (actionWithWarning) {
          const cardWithWarning = response.cards.find(card => 
            card.suggestions.some(suggestion => 
              suggestion.actions.includes(actionWithWarning)
            )
          );

          // Sjekk om kortet er alder-sensitivt og om det skal vises basert på pasientens alder
          const isAgeSensitive = ageSensitiveSummaries.some(summary => cardWithWarning.summary.includes(summary));
          const shouldDisplayCard = patientAge > 65 ? true : !isAgeSensitive;



           return { ...medication, hasCdsCard: shouldDisplayCard, cdsCardData: cardWithWarning };

          }
  
          // If there is no action with a warning, return the medication as is
          return medication;
        });
  
        // Update state with the updated medications list
        return updatedMedications; 
      } else {
        console.error('CDS Hooks response is undefined or not structured as expected.');
      }
    } catch (error) {
      console.error('Error in handleRiskMedicationsClick:', error);
    }
  };
  

  

  const isMedicineAgeSensitive = (cdsCardData) => {
    return ageSensitiveSummaries.some(summary => cdsCardData.summary.includes(summary));
  };


  // Funksjon for å rense medikasjonselementer før de sendes i en CDS Hooks forespørsel
  const cleanMedicationForRequest = (medication) => {
    const { hasCdsCard, cdsCardData, hasCdsCardNyre, cdsCardDataNyre, ...cleanedMedication } = medication;
    return cleanedMedication;
  };

  const sendCDsHooksRequest = async () => {
    if (!searchedPatient) {
      setError('Pasient- eller medikamentinformasjon mangler.');
      return;
    }

    // Rens medikasjoner før du bygger prefetch for CDS Hooks
    const cleanedMedicationsForRequest = patientMedications.map(medication => {
      // Først rens medikasjonselementene
      const cleanedMedication = cleanMedicationForRequest(medication);

      // Sjekk om det nødvendige medikasjonsdata er til stede
      if (!cleanedMedication || !cleanedMedication.medicationCodeableConcept) {
        console.warn('MedicationRequest is missing medicationCodeableConcept:', cleanedMedication);
        return null; // Skip denne medikasjonen hvis den ikke er riktig formatert
      }

      // Bygg så den transformerte ressursen
      const { medicationCodeableConcept, ...restOfMedication } = cleanedMedication;
      return {
        resource: {
          ...restOfMedication,
          medication: { concept: medicationCodeableConcept }
        }
      };
    }).filter(medication => medication !== null); // Fjern eventuelle null-verdier

    const cdsHooksBody = {
      "hookInstance": "e3dfbf01-8d3a-4d17-b0f0-521896f7c9e9",
      "hook": "patient-view",
      "context": {
        "userId": `Patient/${patientId}`,
        "patientId": patientId
      },
      "prefetch": {
        "patient": {
          "resourceType": "Patient",
          "id": patientId,
          "active": true,
          "birthDate": dob,
          "gender": gender
        },
        "medications": {
          "resourceType": "Bundle",
          "type": "searchset",
          "entry": cleanedMedicationsForRequest
        }
      }
    };

    try {
      const response = await fetch(r5FhirServerCdsServices, { // Erstatt 'CDS_HOOKS_URL' med din CDS Hooks server URL
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(cdsHooksBody)
      });

      console.log('HTTP Response:', response); // Logg ut HTTP-svaret for feilsøking


      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('CDS Hooks Response Data:', data); // Logg ut JSON-dataene for feilsøking
  
      return data; // Sørg for å returnere dataene for videre behandling
    } catch (error) {
      console.error('Feil ved sending av CDS Hooks forespørsel:', error);
      setError(`Feil ved sending av CDS Hooks forespørsel: ${error.message}`);
    }
  };



  //RISKIOLEGEMIDLER slutt
  //RISKIOLEGEMIDLER slutt
  //RISKIOLEGEMIDLER slutt

  // NYRE 
  // NYRE 
  // NYRE 




  useEffect(() => {
    // This will run after patientMedications state is updated
    if (patientMedications) {
      console.log('Medication IDs:', patientMedications.map(med => med.id));

      // Perform necessary actions, such as updating UI elements or making further requests
    }
  }, [patientMedications]);


    
  const cleanMedicationRequestId = (id) => {
    return id.split('/_history/')[0];
  };
  


  const handleKidneyClick = async () => {
    try {
      const response = await sendKidneyRequest();
      if (response && Array.isArray(response.cards)) {
        const updatedMedicationsWithKidneyInfo = patientMedications.map(medication => {
          let foundCard = null;
          
          response.cards.forEach(card => {
            const extensions = card.extension['no.felleskatalogen.cds.nedsatt-nyre.legemidler'];
            Object.keys(extensions).forEach(extensionKey => {
              const cleanedId = cleanMedicationRequestId(extensionKey);
              console.log(`Sammenligner ${cleanedId} med MedicationRequest/${medication.id}`);
              if (cleanedId === `MedicationRequest/${medication.id}`) {
                console.log(`Fant kort for medikasjon ${medication.id}`);
                foundCard = card;
              }
            });
          });
  
          console.log(`Updating state for medication ID: ${medication.id} with foundCard:`, foundCard);
          return {
            ...medication,
            hasCdsCardNyre: !!foundCard,
            cdsCardDataNyre: foundCard,
          };
        });
        
        console.log('Updated medications with kidney info:', updatedMedicationsWithKidneyInfo);
        return updatedMedicationsWithKidneyInfo;
      }
    } catch (error) {
      console.error('Error in handleKidneyClick:', error);
    }
  };
  
  
  
  

  const handleShowCdsCardNyre = (cdsCardDataNyre) => {
    console.log('Show CDS Card Nyre with data:', cdsCardDataNyre);
    setCurrentCdsCardNyre(cdsCardDataNyre);
    setShowCdsModalNyre(true);
  };
  

  const sendKidneyRequest = async () => {
    if (!searchedPatient) {
      setError('Pasientinformasjon er ikke tilgjengelig.');
      return;
    }
  
    const observations = patientObservation;
  
    let gfrObservations = observations.filter(obs => 
      obs.code.coding.some(coding => 
        coding.system === "http://snomed.info/sct" && coding.code === "80274001"
      )
    );
  
    gfrObservations.sort((a, b) => new Date(b.effectiveDateTime) - new Date(a.effectiveDateTime));
    let newestGfrObservation = gfrObservations[0];
    let gfrValue = newestGfrObservation ? newestGfrObservation.valueQuantity.value : -1;
  
    const cleanAndFormatMedications = (medications) => {
      return medications.map(medication => {
        if (!medication || !medication.medicationCodeableConcept) {
          console.warn('MedicationRequest is missing medicationCodeableConcept:', medication);
          return null; // Skip this medication if it's not properly formatted
        }
  
        // Strip any previous CDS-related data if present (e.g., from previous interactions)
        const { hasCdsCard, cdsCardData, ...cleanMedication } = medication;
  
        const transformedResource = {
          ...cleanMedication,
          medication: {
            concept: medication.medicationCodeableConcept
          }
        };
  
        return { resource: transformedResource };
      }).filter(Boolean); // Remove any null entries
    };
  
    const r5FormattedMedications = cleanAndFormatMedications(patientMedications);
  
    const createRequestBody = (formattedMedications, gfrValue) => {
      let requestBody = {
        "hookInstance": "e3dfbf01-8d3a-4d17-b0f0-521896f7c9e9",
        "hook": "patient-view",
        "context": {
          "userId": `Patient/${patientId}`,
          "patientId": patientId
        },
        "prefetch": {
          "patient": {
            "resourceType": "Patient",
            "id": patientId,
            "active": true,
            "birthDate": dob,
            "gender": gender
          },
          "medications": {
            "resourceType": "Bundle",
            "type": "searchset",
            "entry": formattedMedications
          }
        }
      };
  
      if (gfrValue !== -1) {
        requestBody.prefetch.observations = {
          "resourceType": "Bundle",
          "type": "searchset",
          "total": 1,
          "entry": [{
            "resource": {
              "resourceType": "Observation",
              "id": "value-in-range-observation-gfr",
              "status": "final",
              "code": {
                "coding": [{
                  "system": "http://snomed.info/sct",
                  "code": "80274001",
                  "display": "Glomerular filtration rate",
                }],
              },
              "subject": {
                "reference": `Patient/${patientId}`,
              },
              "valueQuantity": {
                "value": gfrValue,
                "unit": "ml/min",
                "system": "http://unitsofmeasure.org",
                "code": "mL/min",
              },
            },
          }],
        };
      }
  
      return requestBody;
    };
  
    const requestBody = createRequestBody(r5FormattedMedications, gfrValue);
  
    try {
      const response = await fetch(r5FhirServerCdsServicesNyre, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const dataNyre = await response.json();
      console.log('CDS Hooks Response Data:', dataNyre);
      return dataNyre;
    } catch (error) {
      setError(`Feil ved sending av CDS Hooks forespørsel: ${error.message}`);
      console.error('Feil ved sending av CDS Hooks forespørsel:', error);
    }
  };
  
  
  // NYRE slutt
  // NYRE slutt
  // NYRE slutt
  
  const handleBothClicks = async () => {
    try {
      const riskMedicationsResponse = await handleRiskMedicationsClick().catch(error => {
        console.error('Feil i handleRiskMedicationsClick:', error);
        return null;
      });
  
      const kidneyResponse = await handleKidneyClick().catch(error => {
        console.error('Feil i handleKidneyClick:', error);
        return null;
      });
  
      const combinedUpdates = patientMedications.map(medication => ({
        ...medication,
        ...(riskMedicationsResponse ? riskMedicationsResponse.find(riskMed => riskMed.id === medication.id) || {} : {}),
        ...(kidneyResponse ? kidneyResponse.find(kidneyMed => kidneyMed.id === medication.id) || {} : {})
      }));
  
      setPatientMedication(combinedUpdates);
  
  
    } catch (error) {
      console.error('Feil under kjøring av handleBothClicks:', error);
    }
  };
  


  return (
    <Container className="wrapper">
      <Container className="patientSearch">
        <Button className="home submit-button" onClick={() => navigator(`/`)}>
          <ArrowBackIcon />
        </Button>

        <Row>
          <Col sm={12}>
            <FloatingLabel controlId="floatingTextarea2" label="">
              <Form.Group>
                <Form.Label>
                  <b>Søk etter pasient</b>
                </Form.Label>

                <AutoSuggest
                  type="search"
                  className="findPatientSearch"
                  name="emailSearch"
                  keyWord="Patient"
                  // suggestion={setSuggestedPatieny(props.suggestion)}
                  noResultFoundCallback={() => {
                    resetData()
                    setError("No Result Found")
                  }}
                  suggestCallback={getSuggestedPatient}
                  onClick={() => {}}
                />

                <Form.Text className="helpertext1">
                  {error ? "Ingen resultater funnet" : ""}
                </Form.Text>
              </Form.Group>
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          {firstName && (
            <div className="patientDataTop">
              <span data-patient-id={patientId}>{firstName + " " + lastName}</span>
              {" | " + gender +
                " | " + new Date(dob).toLocaleDateString("nb-NB")}
              <Button
                variant="link"
                onClick={() => setShowPatient(!showPatient)}
              >
                {showPatient
                  ? "Skjul pasientinformasjon"
                  : "Vis pasientinformasjon"}
              </Button>
            </div>
          )}
          <br />
        </Row>
      </Container>
      {showPatient && (
        <div>
          <h2>Pasient</h2>

          <Form onSubmit={() => {}}>
            <Container className="c1" xs={40}>
              <Row xs={40}>
                <Col>
                  <FloatingLabel className="my-3">
                    <Form.Group as={Col}>
                      <Form.Label>Fornavn</Form.Label>
                      <Form.Control
                        required
                        disabled
                        id="first_name"
                        name="first_name"
                        placeholder="Fornavn"
                        type="text"
                        value={firstName}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel className="my-3">
                    <Form.Group as={Col}>
                      <Form.Label>Etternavn</Form.Label>
                      <Form.Control
                        required
                        disabled
                        id="last_name"
                        name="last_name"
                        placeholder="Etternavn"
                        type="text"
                        value={lastName}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </FloatingLabel>
                </Col>

                <Col className="text-left">
                  {" "}
                  <FloatingLabel className="my-3">
                    <Form.Group as={Col}>
                      <Form.Label>Kjønn</Form.Label>

                      <Form.Select
                        as={Col}
                        onChange={(event) => setGender(event.target.value)}
                        value={gender}
                        disabled
                      >
                        <option value=" ">Velg kjønn</option>
                        <option value="female">Kvinne</option>
                        <option value="male">Mann</option>
                        <option value="other">Annet</option>
                        <option value="unknown">Ukjent</option>
                      </Form.Select>
                    </Form.Group>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel className="my-3">
                    <Form.Group as={Col}>
                      <Form.Label>Fødselsdato</Form.Label>

                      <Form.Control
                        type="date"
                        onChange={(event) => setDob(event.target.value)}
                        value={dob}
                        disabled
                      ></Form.Control>
                    </Form.Group>
                  </FloatingLabel>
                </Col>
              </Row>
            </Container>
            <Container className="c2" xs={40}>
              <h3>Adresse</h3>
              <Form.Group className="mb-3" controlId="formGridAddress1">
                <Form.Label>Gate/Vei</Form.Label>
                <Form.Control
                  placeholder="Gate 123"
                  disabled
                  onChange={(event) => setline(event.target.value)}
                  value={line}
                />
              </Form.Group>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>By</Form.Label>
                  <Form.Control
                    onChange={(event) => setCity(event.target.value)}
                    value={city}
                    placeholder="Oslo"
                    disabled
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridState">
                  <Form.Label>Land</Form.Label>
                  <Form.Control
                    onChange={(event) => setCountry(event.target.value)}
                    value={country}
                    placeholder="Norge"
                    disabled
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridZip">
                  <Form.Label>Postnummer</Form.Label>
                  <Form.Control
                    onChange={(event) => setPostalCode(event.target.value)}
                    value={postalCode}
                    placeholder="0123"
                    disabled
                  />
                </Form.Group>
              </Row>
            </Container>
            <Container className="c2" xs={40}>
              <Row xs={40}>
                <Col>
                  <FloatingLabel className="my-3">
                    <Form.Group as={Col}>
                      <Form.Label>E-post adresse</Form.Label>
                      <Form.Control
                        required
                        type="email"
                        placeholder="navn@epost.no"
                        onChange={(event) => setEmail(event.target.value)}
                        value={email}
                        disabled
                      />
                    </Form.Group>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel className="my-3">
                    <Form.Group as={Col}>
                      <Form.Label>Telefon</Form.Label>
                      <Form.Control
                        type="phone"
                        country={"no"}
                        placeholder="+471234567"
                        onChange={(event) => setPhoneNo(event.target.value)}
                        value={phoneNo}
                        disabled
                      />
                    </Form.Group>
                  </FloatingLabel>
                </Col>
              </Row>
            </Container>

            <Container className="c5" xs={40}>
              <h3>Pårørende</h3>
              <Row xs={40}>
                <Col>
                  <FloatingLabel className="my-3">
                    <Form.Group as={Col}>
                      <Form.Label>Fornavn</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Fornavn"
                        onChange={(event) =>
                          setEmergencyFirstName(event.target.value)
                        }
                        value={EmergencyfirstName}
                        disabled
                      />
                    </Form.Group>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel className="my-3">
                    <Form.Group as={Col}>
                      <Form.Label>Etternavn</Form.Label>
                      <Form.Control
                        required
                        disabled
                        type="text"
                        placeholder="Etternavn"
                        value={EmergencylastName}
                        onChange={(event) =>
                          setEmergencyLastName(event.target.value)
                        }
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel className="my-3">
                    <Form.Group as={Col}>
                      <Form.Label>Telefon</Form.Label>
                      <Form.Control
                        type="phone"
                        country={"no"}
                        placeholder="+471234567"
                        disabled
                        onChange={(event) =>
                          setEmergencyphoneNo(event.target.value)
                        }
                        value={EmergencyphoneNo}
                      />
                    </Form.Group>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel className="my-3">
                    <Form.Group as={Col} controlId="formGridCity">
                      <Form.Label>Forhold</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Forhold"
                        disabled
                        onChange={(event) =>
                          setContactTitle(event.target.value)
                        }
                        value={ContactTitle}
                      />
                    </Form.Group>
                  </FloatingLabel>
                </Col>
              </Row>
            </Container>
          </Form>
        </div>
      )}
      <Container>
        <Row>
          {!!searchedPatient ? (
            <Col sm className="conditions innerWrapper">
              <h2>
                Diagnoser/problemstillinger{" "}
                <img src="/assets/symptomz.png" width="30px" alt="ikon" />
              </h2>
              {patientCondition?.map((c, idx) => {
                if (!c || !c.code || !c.code.coding ) {
                  console.warn(`Condition at index ${idx} is missing or not structured correctly.`);
                  return null;
                }
                const condition = c.code;
                const onsetDateTimeString = c.onsetDateTime
                  ? new Date(c.onsetDateTime).toLocaleDateString("nb-NB")
                  : "Ukjent dato";
                // Resten av koden din ...
                return (
                  <Container className="c3 mb-2" key={`condition-${idx}`}>
                    <Card>
                      <Card.Body key={`b-condition-${idx}`}>
                        {onsetDateTimeString} 
                        {": "}
                        <div className="conditionList">
                          <strong>{condition?.coding[0]?.display}</strong>
                        </div>

                        <Button
                          variant="link"
                          size="sm"
                          style={{ cursor: "pointer", paddingRight: "20%" }}
                          onClick={() =>
                            showMoreId === c.id
                              ? setShowMoreId("")
                              : setShowMoreId(c.id)
                          }
                        >
                          {showMoreId === c.id
                            ? "Vis mindre"
                            : "Vis mer"}
                        </Button>
                        {showMoreId === c.id && (
                          <React.Fragment>
                            <div className="conditionListHidden">
                              {c.bodySite && (
                                <p>
                                  <strong>Kroppssted</strong> :{" "}
                                  <div className="conditionList">
                                    {" "}
                                    {c.bodySite[0].coding[0].display}
                                  </div>
                                </p>
                              )}

                              <p>
                                <b>Kategori</b>:{" "}
                                {c.category[0].coding[0].display}
                              </p>
                              {c.clinicalStatus && (
                                <p>
                                  <b>Klinisk status</b> :{" "}
                                  {c.clinicalStatus.coding[0].display}
                                </p>
                              )}

                              {c.note && (
                                <p>
                                  {" "}
                                  <b>Merk</b>: {c.note[0]?.text}
                                </p>
                              )}
                              <div className="metadata">
                                <p>
                                  <strong>Metadata:</strong>
                                </p>
                                <p>
                                  SNOMED CT funn/diagnose:<br></br>
                                  <div className="termNorwegian">
                                    Term: {condition?.coding[0]?.display}<br></br>
                                    SCTID: {condition?.coding[0]?.code} 
                                  </div>
                                </p>
                                {c.bodySite && (
                                  <p>
                                    SNOMED CT kroppssted:{" "}
                                    <div className="termNorwegian">
                                      {c.bodySite[0].coding[0].display}{" "}
                                      {c.bodySite[0].coding[0].code}
                                    </div>
                                  </p>
                                )}
                              </div>
                              <br></br>
                       {/*    Redigerknapp under utvikling
                              <Button 
                              variant="primary"
                              size="sm"
                              onClick={() => handleOpenModal(c)}>
                              Rediger
                            </Button>

                            */}
                            <Button
                              variant="danger"
                              className="deleteButton"
                              size="sm"
                              onClick={() => deleteFhirResource('Condition', c.id)}
                            >
                              Slett
                            </Button>
                            </div>
                          </React.Fragment>
                        )}
                      </Card.Body>
                    </Card>
                  </Container>
                )
              })}
              <Button
                className="add-item submit-button"
                onClick={() =>
                  navigator(`/condition/${searchedPatient?.id}`, {
                    state: { path: "/patient", id: searchedPatient?.id },
                  })
                }
              >
                Legg til ny problemstilling
              </Button>
            </Col>
          ) : null}
          {!!searchedPatient ? (
            <Col sm className="observations innerWrapper">
              <h2>
                Observasjoner{" "}
                <img src="/assets/observationz.png" width="30px" alt="ikon" />
              </h2>
              {patientObservation?.map((o, idx) => {
                  if (!o || !o.code || !o.code.coding || o.code.coding.length === 0) {
                    console.warn(`Observation at index ${idx} is missing the correct 'coding' data.`);
                    return null;
                  }

                const observation = o.code;
                const observationValue = o.valueQuantity
                const dateTime = o.effectiveDateTime
                return (
                  <Container className="mb-2" key={`observation-${idx}`}>
                    <Card>
                      <Card.Body key={`b-observation-${idx}`}>
                         {dateTime ? new Date(dateTime).toLocaleDateString("nb-NB") : 'Ukjent dato'}
                         {": "}
                        <div className="conditionList">
                           <strong>{observation?.coding[0]?.display || 'Ukjent observasjon'}</strong>
                        </div>
                        {observationValue?.value && (
                          <p className="observationValue">
                            , Verdi: {observationValue?.value}{" "}
                            {observationValue?.unit}
                          </p>
                        )}
                        <Button
                          variant="link"
                          size="sm"
                          style={{ cursor: "pointer", paddingRight: "20%" }}
                          onClick={() =>
                            showMoreId === o.id
                              ? setShowMoreId("")
                              : setShowMoreId(o.id)
                          }
                        >
                          {showMoreId === o.id
                            ? "Vis mindre"
                            : "Vis mer"}
                        </Button>

                        {showMoreId === o.id && (
                          <React.Fragment>
                            <div className="metadata">
                              <p>
                                <strong>Metadata:</strong>
                              </p>
                              <p>
                                SNOMED CT observasjon:<br></br>
                                <div className="termNorwegian">
                                  Term: {observation?.coding[0]?.display}<br></br>
                                  SCTID: {observation?.coding[0]?.code}
                                </div>
                              </p>
                              </div>
                              <br></br>
                              <Button
                              variant="danger"
                              className="deleteButton"
                              size="sm"
                              onClick={() => deleteFhirResource('Observation', o.id)}
                            >
                              Slett observasjon
                            </Button>

                          </React.Fragment>
                        )}
                      </Card.Body>
                    </Card>
                  </Container>
                )
              })}
              <Button
                className="add-item submit-button"
                onClick={() =>
                  navigator(`/observation/${searchedPatient?.id}`, {
                    state: { path: "/patient", id: searchedPatient?.id },
                  })
                }
              >
                Legg til ny observasjon
              </Button>
            </Col>
          ) : null}

          {!!searchedPatient ? (
            <Col className="innerWrapper medications" sm>
              <h2>
                Medisinering{" "}
                <img src="/assets/medicines.png" width="30px" alt="ikon" />
              </h2>

              {patientMedications?.map((med, idx) => {
                if (!med || !med.medicationCodeableConcept || !med.medicationCodeableConcept.coding) {
                  console.warn(`Medication data for index ${idx} is missing.`);
                  return null;
  }
                //const medicationCoding = med.medicationCodeableConcept.coding;
                //const medicationDisplay = medicationCoding && medicationCoding.length > 0 ? medicationCoding[0]?.display : "Ingen medisin";
                //const startPeriod = med.dispenseRequest.validityPeriod?.start;
                //const startDateString = startPeriod ? new Date(startPeriod).toLocaleDateString("nb-NB") : "Ukjent startdato";
                               
                const medication = med.medicationCodeableConcept.coding
                const condition = med.reasonReference
                return (
                  <Container className="c3 mb-2" key={`medication-${idx}`}>
                    <Card>
                      <Card.Body key={`b-medication-${idx}`}>
                      {new Date(
                          med.dispenseRequest.validityPeriod.start
                        ).toLocaleDateString("nb-NB")}
                        {": "}
                        <div className="conditionList">
                          <strong>
                            {medication
                              ? medication[0]?.display
                              : "Ingen medisin"}
                          </strong>
                        </div>
                        <div className="buttonContainer d-flex justify-content-between">
                            <Button
                              variant="link"
                              size="sm"
                              style={{ cursor: "pointer", paddingRight: "20%" }}
                              onClick={() =>
                                showMoreId === med.id
                                  ? setShowMoreId("")
                                  : setShowMoreId(med.id)
                              }
                            >
                           {showMoreId === med.id
                            ? "Vis mindre"
                            : "Vis mer"}
                        </Button>
                        <div>
                        {med.hasCdsCard && (
                            <Button
                              variant="info"
                              size="sm"
                              className="ml-2"
                              onClick={() => handleShowCdsCard(med.cdsCardData)} // Sørg for at denne funksjonen er definert og henter riktig data
                            >
                              <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                className={patientAge > 65 && isMedicineAgeSensitive(med.cdsCardData) ? "text-warning1" : "text-warning2"} 
                              />
                            </Button>
                            )}
                          {console.log('Rendering medication with idx:', idx, 'hasCdsCard:', med.hasCdsCard, 'hasCdsCardnyre:', med.hasCdsCardnyre)}
                          {console.log('Should render button:', med.hasCdsCardnyre)}
                          {med.cdsCardDataNyre && (
                                <Button
                                  variant="info"
                                  size="sm"
                                  className="ml-2"
                                  onClick={() => handleShowCdsCardNyre(med.cdsCardDataNyre)}
                                >
                               <img
                            src={kidneySymbolImage}
                            alt="Nyresymbol"
                            className="text-kidney3" />
                                </Button>
                            )}
                            </div>
                            </div>
                          {showMoreId === med.id && (
                          <React.Fragment>
                            <p>
                              {condition && (
                                <span>
                                  <b>Indikasjon: </b>
                                  <div className="termNorwegian">
                                    {condition[0]?.display}
                                  </div>
                                </span>
                              )}
                            </p>
                            <p>
                              <b>Status: </b>
                              {med.status}
                            </p>
                            {/*<p>
                              <b>Prioritet</b>: {med.priority}
                            </p>*/}

                            <p>
                              {med.courseOfTherapyType &&
                                med.courseOfTherapyType.coding[0] && (
                                  <span>
                                    <b>Bruk: </b>
                                    {
                                      med.courseOfTherapyType.coding[0]
                                        .display
                                    }
                                  </span>
                                )}
                            </p>
                            {med.note?.text && (
                              <p>
                                <b>Merk: </b>
                                {med.note.text} <br />
                                {med.note.time}
                              </p>
                            )}
                            {med.dosageInstruction?.length > 0 && (
                              <p>
                                <b>Dosering: </b>
                                {med.dosageInstruction[0]?.text}
                              </p>
                            )}

                            <p>
                              <strong>Reit: </strong>
                              {
                                med.dispenseRequest
                                  .numberOfRepeatsAllowed
                              }
                            </p>

                            {med.dispenseRequest?.quantity && (
                              <p>
                                <span>
                                  <b>Antall: </b>
                                  {
                                    med.dispenseRequest?.quantity
                                      ?.value
                                  }
                                </span>
                              </p>
                            )}
                            {med.dispenseRequest?.validityPeriod
                              ?.end && (
                              <p>
                                <b>Utløper: </b>
                                {new Date(
                                  med.dispenseRequest?.validityPeriod?.end
                                ).toLocaleDateString("nb-NB")}
                              </p>
                            )}
                                        
                            <Button
                              variant="danger"
                              className="deleteButton"
                              size="sm"
                              onClick={() => deleteFhirResource('MedicationRequest', med.id)}
                            >
                              Slett medisinering
                            </Button>

                            <Button
                              className="secondaryCta"
                              onClick={() => handleGeneratePromptMed(med)} // Åpne meldingsmodalen
                              size="sm"
                            >
                              KI-Legemiddelbytte
                            </Button>



                          </React.Fragment>
                        )}
                      </Card.Body>
                    </Card>
                  </Container>
                )
              })}
              {/* <Container> */}

              <Button
                className="add-item submit-button"
                onClick={() =>
                  navigator(`/medication/${searchedPatient?.id}`, {
                    state: { path: "/patient", id: searchedPatient?.id },
                  })
                }
                style={{ marginRight: 5 }}
              >
                Legg til ny medisinering
              </Button>
              {/* </Container> */}
              <br />

              <Button
                className="secondaryCta"
                onClick={() =>
                  window.open(
                    `${bivirkningUrl}${
                      patientMedications.map((med) => {
                        const medication =
                          med?.medicationCodeableConcept?.coding
                        return medication[0]?.code
                      }) +
                      "&term=" +
                      patientCondition.map((cond) => {
                        const condition = cond.code?.coding
                        return condition[0]?.code
                      }) +
                      "&termfelt=sctid"
                    }`,
                    "_blank"
                  )
                }
              >
                Bivirkningssøk
              </Button>
              <Button
                className="secondaryCta"
                onClick={() =>
                  window.open(
                    `${interaksjonUrl}${
                      patientMedications.map((med) => {
                        const medication =
                          med?.medicationCodeableConcept?.coding
                        return medication[0]?.code
                      }) +
                      "&term=" +
                      patientCondition.map((cond) => {
                        const condition = cond.code?.coding
                        return condition[0]?.code
                      }) +
                      "&termfelt=sctid"
                    }`,
                    "_blank"
                  )
                }
              >
                Interaksjonsanalyse
              </Button>
            {/*  <Button className="secondaryCta" onClick={handleRiskMedicationsClick}>
              Risikolegemidler
            </Button>
            <Button className="secondaryCta" onClick={handleKidneyClick}>
              Nyre
              </Button>*/}
            <Button className="secondaryCta" onClick={handleBothClicks}>
              Legemiddelgjennomgang
            </Button>
            <Button
                className="secondaryCta"
                onClick={handleGeneratePrompt}
              >
                KI-Legemiddelgjennomgang
              </Button>
            </Col>
          ) : null}
        </Row>
      </Container>{" "}
     {/* Redigerknapp under utvikling
      <ConditionEditModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        condition={conditionToEdit}
        handleUpdate={handleUpdateCondition}
      /> 
*/}
        
       {/* <Modal show={showGptModal} onHide={handleCloseGptModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>KI-Legemiddelgjennomgang</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <GptChat prompt={gptPrompt} onClose={handleCloseGptModal} />
                </Modal.Body>
            </Modal>

*/}
            <Modal show={showGptModal} onHide={handleCloseGptModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>KI-Legemiddelgjennomgang</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showGptModal && (
            <ChatBox
              askLLM={async (chat) => {
                console.log('askLLM called with chat:', chat);
                const response = await streamFromLLM(await getToken(), chat);
                console.log('Received response from streamFromLLM:', response);
                return response;
              }}
              medicalText={gptPrompt}
              autoSendFirstMessage={true} // Pass autoSendFirstMessage prop
            />
          )}
        </Modal.Body>
      </Modal>




           {/* <Modal show={showGptModalMed} onHide={handleCloseGptModalMed} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>KI-Legemiddelbytte</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <GptChatMed prompt={gptPromptMed} onClose={handleCloseGptModalMed} />
                </Modal.Body>
</Modal>*/}

                    {
          showCdsModal && currentCdsCard && (
            <Modal show={showCdsModal} onHide={onClose}>
              <CurrentCdsComponent
                cdsCardData={currentCdsCard}
                patientAge={patientAge}
                onClose={onClose}
              />
            </Modal>
          )
        }
        {
          showCdsModalNyre && currentCdsCardNyre && (
            <Modal show={showCdsModalNyre} onHide={onCloseNyre}>
              <CurrentCdsComponentNyre
                cdsCardDataNyre={currentCdsCardNyre}
                onCloseNyre={onCloseNyre}
              />
            </Modal>
          )
        }
 {/* 
    <Modal show={showGptModalMed} onHide={handleCloseGptModalMed} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>KI-Legemiddelbytte</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <GptChatMed prompt={gptPromptMed} onClose={handleCloseGptModalMed} />
        </Modal.Body>
      </Modal>
      */}

      <Modal show={showGptModalMed} onHide={handleCloseGptModalMed} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>KI-Legemiddelbytte</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showGptModalMed && (
            <GptChatMedStream
              prompt={gptPromptMed}
              onClose={handleCloseGptModalMed}
            />
          )}
        </Modal.Body>
      </Modal>

              
    </Container>
  )
}

export default Patient